
























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import enrollmentService from '@/sparkmembers/services/enrollment';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';
import { LocationMemberSegment } from '@/libs/member';

const AuthModule = namespace('auth');
const SchoolModule = namespace('school');

@Component
export default class DesactiveEnrollmentModal extends Vue {
  @Prop({ required: true }) modalId!: string;
  @Prop({ required: true }) options!: any;

  @AuthModule.State loggedUser!: any;
  @SchoolModule.State selectedSchool!: any;

  products: any = null;
  isSearching: boolean = false;
  isActive: boolean = false;

  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent: any, modalId: string) => {
      if (modalId === 'desactive-enrollment-modal') {
        this.products = this.options.products.length === 1 ? this.options.products[0].id : null;
        this.isActive = true;
      }
    });
  }

  async updateEnrollments() {
    this.$emit('isLoading', true);
    const enrollmentsIds: number[] = this.getEnrollmentsIds();

    try {
      await enrollmentService.massDeactivateEnrollments(enrollmentsIds);
      this.$emit('loadMembers');

      ToastHelper.successMessage(this.$t('sparkmembers.members-list.desactive-enrollment-modal.toast.success'));

      this.trackingMemberEnrollmentDeactivated(
        this.options.members.length,
        Array.isArray(this.products) ? this.products.length : 1
      );
    } catch (err) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.members-list.desactive-enrollment-modal.toast.error'));
    }
  }

  getEnrollmentsIds(): number[] {
    let enrollmentsId: number[] = [];

    this.options.members.forEach((member: any) => {
      const memberEnrolls = member.registrations[0].enrollments;

      if (this.products && this.products.length > 0) {
        this.products.forEach((product: any) => {
          memberEnrolls.forEach((item: any) => {
            if (item.school_product.id === product.id && item.status === 'active') {
              enrollmentsId.push(item.id);
            }
          });
        });
      } else {
        memberEnrolls.forEach((item: any) => {
          if (item.school_product.id === this.products && item.status === 'active') {
            enrollmentsId.push(item.id);
          }
        });
      }
    });

    return enrollmentsId;
  }

  trackingMemberEnrollmentDeactivated(member_count: number, product_count: number) {
    const location = this.$route.params.id
      ? LocationMemberSegment.MEMBERS_DETAILS_PAGE
      : LocationMemberSegment.MEMBERS_LIST_PAGE;
    TrackingHelper.trackMemberEnrollmentDeactivated(
      location,
      this.loggedUser.email,
      this.selectedSchool.id,
      member_count,
      product_count
    );
  }
}
